document.addEventListener("DOMContentLoaded", function() {

	document.addEventListener('click', function (event) {
		if (event.target.closest('.hov-container')) {
            
            document.body.classList.add('bloque');
            document.getElementById('popUp').classList.add('actif');

            const lurl = event.target.closest('.hov-container').querySelector('img').getAttribute('src');

            gsap.fromTo('#popUp > div', {
                y:'100%',
                // opacity:0
            }, {
                y:'0%',
                // opacity:1,
                duration:1,
                stagger:0.15,
                ease:'power4.out'
            })

            document.getElementById('popUp').querySelector('.photo img').setAttribute('src', lurl)
            document.getElementById('popUp').querySelector('.nom').innerHTML = event.target.closest('.hov-container').querySelector('h3').innerHTML;
            document.getElementById('popUp').querySelector('.poste').innerHTML = event.target.closest('.hov-container').querySelector('p').innerHTML;
            document.getElementById('popUp').querySelector('.bio').innerHTML = event.target.closest('.hov-container').getAttribute('data-bio');
            document.getElementById('popUp').querySelector('a').innerHTML = event.target.closest('.hov-container').getAttribute('data-texte-lien');
            document.getElementById('popUp').querySelector('a').setAttribute('href',event.target.closest('.hov-container').getAttribute('data-lien'));

       	
        }else if (event.target.closest('.closePop')) {

            gsap.to('#popUp > div', {
                y:'100%',
                // opacity:1,
                duration:1,
                stagger:0.15,
                ease:'power4.in',
                onComplete:() => {
                    document.body.classList.remove('bloque');
                    document.getElementById('popUp').classList.remove('actif');
                }
            })

       	}
    });



	//
    // page medias
    //
    if(document.querySelector('.post-type-archive-medias')) {
        document.querySelectorAll('.unMedia').forEach(el => {
        	if(el.querySelector('em')){
            	el.setAttribute('href',el.querySelector('em').innerHTML)
            }
        })
    }


    if(document.querySelector('.listeOffres')){
    	document.querySelector('.listeOffres').innerHTML = document.getElementById('offres').innerHTML;
    }
		
});